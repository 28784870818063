<script lang="ts" setup>
import { $fetch } from '@@home/plugins/fetch';
import { useAsyncData } from '@@home/hooks/async-data';
import dayjs from 'dayjs';
import NavigateButton from '@ssr/home/components/NavigateButton.vue';

const { data: journalism } = useAsyncData<any[], Record<string, any>>(
  'home:journalism',
  () => $fetch('/news/v1/list', { data: { limit: 6 } }),
  {
    default: () => [],
    transform: (data) => data?.newsVOList ?? [],
  },
);
</script>

<template>
  <section class="page-home-journalism">
    <div class="journalism-container mx-auto max-w-1360px py-106px <2xl:max-w-1200px">
      <h2 class="mb-0 mt-0 text-center text-50px font-medium">新闻中心</h2>
      <NavigateButton to="/journalism" class="mt-16px">查看更多新闻</NavigateButton>
      <div class="mt-48px flex flex-wrap">
        <div class="journalism-card w-432px flex flex-wrap rounded-4px <2xl:w-384px" v-for="item in journalism" :key="item.id">
          <RouterLink :to="`/journalism/detail/${item.id}`" class="block w-full no-underline">
            <img :data-src="item.cover" class="lazyload block h-208px w-100% object-cover" :alt="item.title">
            <div class="box-border h-132px p-16px">
              <h3 class="m-0 h-24px truncate text-20px color-hex-2d2d2d font-medium leading-24px" :title="item.title">{{ item.title }}</h3>
              <p class="line-clamp-2 m-0 mt-6px h-48px text-16px color-hex-9A9EA6 leading-24px" :title="item.intro">{{ item.intro }}</p>
              <small class="mt-6px block h-16px text-right text-14px color-hex-ADB1BA leading-16px">{{ dayjs(item.releaseTime).format('YYYY-MM-DD') }}</small>
            </div>
          </RouterLink>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.page-home-journalism {
  width: 100%;
  color: #2d2d2d;
  background-color: #fff;

  .journalism-container {
    .journalism-card {
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.04);
      transition: transform .2s;

      &:hover {
        @apply translate-y--12px <2xl:translate-y--6px;

        .go-detail {
          color: #fff;
          background: #1180ff;
          border: unset;
        }
      }

      & + .journalism-card {
        @apply ml-32px <2xl:ml-24px;
      }

      &:nth-child(3n + 1) {
        margin-left: 0;
      }

      &:nth-child(n + 4) {
        @apply mt-48px <2xl:mt-24px;
      }

      h3 {
        text-shadow: 0 0 16px rgba(0, 0, 0, 0.04);
      }
    }
  }
}
</style>
